import { css } from "@emotion/react"
import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CustomAccordion from "../../components/common/accordian"
import Buttons from "../../components/common/button"
import Frame from "../../components/common/frame"
import PopUpForm from "../../components/common/PopUpForm"
import SVGIcon from "../../components/common/SVGIcon"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/community.scss"
import "../../styles/pages/refer-a-friend.scss"

const Community = props => {
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter company",
    },
  ]
  return (
    <>
      <StaticQuery
        query={graphql`
          query community {
            SuperOps {
              pages(where: { title: "Superops Community" }) {
                title
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                heroSection {
                  type
                  backgroundColor
                  backgroundImage {
                    url
                  }
                  animAltText
                  heroExcerptList
                  heading {
                    html
                    text
                  }
                  content {
                    html
                    text
                  }
                  heroImage {
                    url
                  }
                  primaryButtonText
                  primaryButtonLink
                  heroExcerpt
                }
                faqComponent {
                  heading
                  description
                  faqs {
                    question
                    answerrt {
                      html
                    }
                  }
                }
                newField {
                  ... on SuperOps_Card {
                    cardName
                    subtext
                    text
                    image {
                      url
                    }
                  }
                  ... on SuperOps_CtaFeature {
                    __typename
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
                pageImages {
                  url
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <div className="community">
              {data.SuperOps.pages.map(page => {
                const {
                  seo,
                  newField,
                  heroSection,
                  pageImages,
                  faqComponent,
                } = page
                return (
                  <>
                    <Frame seo={seo} IsFooterVisble="No">
                      <div>
                        <PopUpForm
                          visibility={popupVisibility}
                          togglePopup={val => {
                            setPopupVisibility(val)
                          }}
                          formData={formdata}
                          endpoint={process.env.HUBSPOT_COMMUNITY_FORM_ENDPOINT}
                          url={process.env.HUBSPOT_COMMUNITY_FORM_URL}
                          formName="Superops Community Page - Superops"
                          successMsg="Submitted!"
                          afterSumbitRedirectUrl="community/success"
                          additionalFormProps={{ IpStackData: true }}
                        />
                      </div>
                      <main>
                        <section
                          className="hero text-center"
                          css={css`
                            background: ${heroSection.backgroundColor};
                            .bg-lines {
                              background-image: url(${heroSection
                                .backgroundImage.url &&
                                heroSection.backgroundImage.url});
                            }
                          `}
                        >
                          <div className="bg-lines">
                            <Container className="Layout-container">
                              <div className="position-relative svgs d-dsk down-to-up-5">
                                <SVGIcon
                                  name="community/community_1"
                                  className="position-absolute img-1"
                                />
                                <SVGIcon
                                  name="community/community_2"
                                  className="position-absolute img-2"
                                />
                                <SVGIcon
                                  name="community/community_3"
                                  className="position-absolute img-3"
                                />
                                <SVGIcon
                                  name="community/community_4"
                                  className="position-absolute img-4"
                                />
                              </div>
                              <div className="down-to-up-1">
                                <SVGIcon
                                  name="community/CommunityLogo"
                                  className="mt48"
                                />
                              </div>
                              <div className="down-to-up-2">
                                <h1 className="heading mx-auto fw-bold">
                                  {heroSection.heading.text}
                                </h1>
                              </div>
                              <div className="down-to-up-3">
                                <Col
                                  lg={5}
                                  className="d-inline-flex justify-content-center"
                                >
                                  <div className="p16 price-container mt32 mb38 justify-content-between">
                                    <p className="description fw-500  mb0">
                                      {heroSection.content.text}
                                    </p>
                                    <span className="fw-800">
                                      {heroSection.heroExcerpt}
                                    </span>
                                  </div>
                                </Col>
                              </div>
                              <div className="down-to-up-4">
                                <Buttons
                                  theme="primary-new mb50"
                                  arrow
                                  text={heroSection.primaryButtonText}
                                  onClick={() => togglePopup()}
                                />{" "}
                              </div>
                              <div className="down-to-up-5">
                                <div className="d-flex justify-content-center">
                                  <div className="benefit-parent row">
                                    {heroSection.heroExcerptList.map(el => {
                                      return (
                                        <Col
                                          lg={5}
                                          className="benefits d-flex "
                                        >
                                          <SVGIcon
                                            name="greenTick"
                                            className="tick-logo"
                                          />
                                          <div className="p14">
                                            <p className="name m-0">{el}</p>
                                          </div>
                                        </Col>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </Container>
                          </div>
                        </section>
                        <section className="down-to-up-5">
                          <div className="faq">
                            <Container className="Layout-container">
                              <Row className="justify-content-between">
                                <Col lg={4} className="p18">
                                  <h3 className="fw-bold">
                                    {faqComponent.heading}
                                  </h3>
                                  <p className="mb16">
                                    {faqComponent.description}
                                  </p>
                                </Col>
                                <Col
                                  lg={8}
                                  className="d-flex justify-content-center"
                                >
                                  <div className="section">
                                    <CustomAccordion
                                      items={faqComponent.faqs}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </section>
                      </main>
                    </Frame>
                  </>
                )
              })}
            </div>
          </>
        )}
      />
    </>
  )
}
export default Community
